import { graphql } from "gatsby"
import { ActivityFiltersSection } from "src/components/ActivityFiltersSection"
import AssociationList from "src/components/AssociationList"
import Layout from "src/layout"
import { DefaultTheme } from "styled-components"

interface Data {
  assolib: {
    allAssociations: [Association]
    allDomainTagCounts: [DomainTagCount]
  }
}

interface PageContext {
  tagId: ID
  theme: DefaultTheme
}

const TagTemplate: GatsbyPage<Data, PageContext> = ({ data: { assolib }, pageContext: { tagId, theme } }) => {
  return (
    <Layout theme={theme}>
      <ActivityFiltersSection tagId={tagId} />
      <AssociationList associations={assolib.allAssociations} tagCounts={assolib.allDomainTagCounts} tagId={tagId} />
    </Layout>
  )
}

export default TagTemplate

export const query = graphql`
  query($tagId: ID!, $domainId: ID!) {
    assolib {
      allAssociations(tags: [$tagId], domain: $domainId) {
        ...SearchAssociationFields
      }
      allDomainTagCounts {
        tag {
          id
          name
        }
      }
    }
  }
`
